import "./QuestConnector.css";

const QuestConnector = () => {

    return (
        <div className="quest-connector-container">
            <div className="quest-connector">
                
            </div>
        </div>
    )
}

export default QuestConnector;